"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_material_1 = require("@mui/icons-material");
const material_1 = require("@mui/material");
const ICON_TOP_MARGIN = 2.8;
const ExpandIcon = (0, material_1.styled)(icons_material_1.ArrowForwardIosSharp)(({ theme }) => ({
    fontSize: theme.spacing(2),
}));
const StyledAccordionSummary = (0, material_1.styled)(material_1.AccordionSummary)(({ theme }) => ({
    paddingRight: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        alignSelf: 'flex-start',
        marginTop: theme.spacing(ICON_TOP_MARGIN),
    },
    '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded': {
        marginLeft: theme.spacing(2),
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'transparent',
    },
}));
function AccordionSummary(props) {
    return (0, jsx_runtime_1.jsx)(StyledAccordionSummary, { ...props, expandIcon: (0, jsx_runtime_1.jsx)(ExpandIcon, {}) });
}
exports.default = AccordionSummary;
