// eslint-disable-next-line
declare let __webpack_public_path__: string;

import './index.css';

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { ThemeProvider } from '@replicant/design-system';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SnackbarProvider } from 'notistack';
import type { ConfigData } from 'rachael-server';
import { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import Loader from './components/Loader/Loader';
import { ConfigContext } from './config';
import Sentry, { onConfigLoaded } from './sentry';
import { StatsigWrapper } from './statsig';
import { theme } from './theme';

declare global {
  interface Window {
    configData: Promise<ConfigData>;
  }
}

const App = lazy(() => import('./App'));
const Invitation = lazy(() => import('./pages/Invitation'));
const VIVRForm = lazy(() => import('./pages/VIVRForm'));
const NotAuthenticated = lazy(() => import('./pages/NotAuthenticated'));
const NotAuthorized = lazy(() => import('./pages/NotAuthorized'));

const queryClient = new QueryClient();

const AppLoader = ({ configData }: { configData: ConfigData }) => {
  const Page = configData.invitation
    ? Invitation
    : configData.vivr
    ? VIVRForm
    : !configData.user
    ? NotAuthenticated
    : configData.permissions.length === 0
    ? NotAuthorized
    : App;

  return (
    <Sentry.ErrorBoundary>
      <StatsigWrapper configData={configData}>
        <QueryClientProvider client={queryClient}>
          <ConfigContext.Provider value={configData}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CssBaseline />
                  <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <Suspense fallback={<Loader />}>
                      <Page />
                    </Suspense>
                  </SnackbarProvider>
                </LocalizationProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </ConfigContext.Provider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </StatsigWrapper>
    </Sentry.ErrorBoundary>
  );
};

const root = createRoot(document.getElementById('root') as HTMLElement);

window.configData
  .then((configData) => {
    onConfigLoaded(configData);
    root.render(<AppLoader configData={configData} />);
  })
  .catch((e) => {
    Sentry.captureException(e);
    root.render(<h3>Failed to load application configuration.</h3>);
  });
