"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Checkbox = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const form_control_label_1 = require("./form-control-label");
function Checkbox(props) {
    const { label, dense, typography, labelPlacement, ...checkboxProps } = props;
    const checkbox = (0, jsx_runtime_1.jsx)(material_1.Checkbox, { ...checkboxProps });
    return label ? ((0, jsx_runtime_1.jsx)(form_control_label_1.FormControlLabel, { dense: dense, typography: typography, control: checkbox, label: label, labelPlacement: labelPlacement })) : (checkbox);
}
exports.Checkbox = Checkbox;
