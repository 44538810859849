"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_material_1 = require("@mui/icons-material");
const material_1 = require("@mui/material");
function DeleteButton(props) {
    const { variant, ...iconButtonProps } = props;
    return ((0, jsx_runtime_1.jsx)(material_1.IconButton, { ...iconButtonProps, children: variant === 'rounded' ? (0, jsx_runtime_1.jsx)(icons_material_1.Cancel, {}) : (0, jsx_runtime_1.jsx)(icons_material_1.Clear, {}) }));
}
exports.DeleteButton = DeleteButton;
