"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputGroup = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_1 = require("react");
const StyledDivider = (0, material_1.styled)(material_1.Divider)(({ theme }) => {
    const lineAdjust = 1.5;
    return {
        marginTop: theme.spacing(1 + lineAdjust),
        marginBottom: theme.spacing(lineAdjust),
        marginLeft: theme.spacing(1),
    };
});
function InputGroup({ children, className }) {
    const arrChildren = react_1.Children.toArray(children);
    const formattedChildren = [];
    react_1.Children.forEach(arrChildren, (child, idx) => {
        const isLast = idx === arrChildren.length - 1;
        formattedChildren.push((0, jsx_runtime_1.jsx)(material_1.Grid, { item: true, children: child }, idx));
        if (!isLast) {
            formattedChildren.push((0, jsx_runtime_1.jsx)(StyledDivider, { orientation: 'vertical', flexItem: true, variant: 'middle' }, `${idx}_divider`));
        }
    });
    return ((0, jsx_runtime_1.jsx)(material_1.Grid, { direction: 'row', container: true, item: true, spacing: 1, alignItems: 'center', className: className, children: formattedChildren }));
}
exports.InputGroup = InputGroup;
