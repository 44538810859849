"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_1 = require("react");
const style_1 = require("./style");
const DOTTED_LINE_OFFSET_ADJUST = -4;
const DOTTED_LINE_HEIGHT_ADJUST = -3;
const DOTTED_LINE_WIDTH_ADJUST = -1;
const CONTENT_MARGIN_LEFT = 4;
const CONTENT_MARGIN_TOP = -2;
const StyledAccordionDetails = (0, material_1.styled)(material_1.AccordionDetails)(({ theme }) => {
    return {
        padding: theme.spacing(0),
        marginBottom: theme.spacing(1),
        '& > .content': {
            position: 'relative',
            top: theme.spacing(CONTENT_MARGIN_TOP),
            marginLeft: theme.spacing(CONTENT_MARGIN_LEFT),
        },
    };
});
function AccordionDetails(props) {
    const children = props.children;
    const ref = (0, react_1.useRef)(null);
    const theme = (0, material_1.useTheme)();
    const [width, setWidth] = (0, react_1.useState)(0);
    const [height, setHeight] = (0, react_1.useState)(0);
    const [offset, setOffset] = (0, react_1.useState)(0);
    function spacingPixels(value) {
        return parseInt(theme.spacing(value), 10);
    }
    function handleResize() {
        const accordionSummary = ref.current
            ?.closest('.MuiAccordion-root.Mui-expanded')
            ?.getElementsByClassName('MuiAccordionSummary-root')
            .item(0);
        if (ref.current && accordionSummary) {
            setWidth(ref.current.clientWidth + spacingPixels(DOTTED_LINE_WIDTH_ADJUST));
            setHeight(ref.current.clientHeight + spacingPixels(DOTTED_LINE_HEIGHT_ADJUST));
            setOffset(accordionSummary.clientHeight + spacingPixels(DOTTED_LINE_OFFSET_ADJUST));
        }
    }
    (0, react_1.useLayoutEffect)(() => {
        handleResize();
    }, []);
    (0, react_1.useEffect)(() => {
        const parentAccordion = ref.current?.closest('.MuiAccordion-root');
        if (!parentAccordion) {
            return;
        }
        const resizeObserver = new ResizeObserver(handleResize);
        resizeObserver.observe(parentAccordion);
        return () => resizeObserver.disconnect();
    }, []);
    return ((0, jsx_runtime_1.jsxs)(StyledAccordionDetails, { ...props, ref: ref, children: [(0, jsx_runtime_1.jsx)(style_1.DashedBorder, { width: width, height: height, offset: offset }), (0, jsx_runtime_1.jsx)("div", { className: 'content', children: children })] }));
}
exports.default = AccordionDetails;
