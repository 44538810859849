"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Select = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
function Select(props) {
    const id = props?.id || props.label.toLowerCase().split(' ').join('-');
    return ((0, jsx_runtime_1.jsxs)(material_1.FormControl, { children: [(0, jsx_runtime_1.jsx)(material_1.InputLabel, { id: `${id}-label`, children: props.label }), (0, jsx_runtime_1.jsx)(material_1.Select, { id: id, labelId: `${id}-label`, ...props })] }));
}
exports.Select = Select;
