"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashedBorder = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
function DashedBorder({ width, height, offset }) {
    const padding = 8;
    const theme = (0, material_1.useTheme)();
    return ((0, jsx_runtime_1.jsx)("svg", { viewBox: '0 0 1 1', preserveAspectRatio: 'none', style: {
            position: 'relative',
            top: -offset,
            left: padding,
            overflow: 'visible',
            height: '1px',
            width: '1px',
        }, children: (0, jsx_runtime_1.jsx)("path", { d: `M0 0 V${height + offset} H${width}`, fill: 'none', stroke: theme.palette.grey[400], strokeDasharray: '10', strokeWidth: '2', strokeLinecap: 'round', strokeLinejoin: 'round' }) }));
}
exports.DashedBorder = DashedBorder;
