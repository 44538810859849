"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditableTitle = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_1 = require("react");
const text_field_1 = require("./text-field");
const VRT_PADDING = 1;
const HRZ_PADDING = 1.5;
const StyledButton = (0, material_1.styled)(material_1.Button)(({ theme }) => ({
    padding: `${theme.spacing(VRT_PADDING)} ${theme.spacing(HRZ_PADDING)}`,
    ':hover': {
        backgroundColor: theme.palette.action.selected,
    },
}));
function EditableTitle(props) {
    const { size, value, setValue } = props;
    const [editingTitle, setEditingTitle] = (0, react_1.useState)(false);
    const [titleDraft, setTitleDraft] = (0, react_1.useState)(value);
    const typographyVariant = size === 'small' ? 'body1' : 'h6';
    function handleSave(event) {
        if (event.key === 'Enter') {
            setValue(titleDraft);
            setEditingTitle(false);
        }
    }
    function handleChange(event) {
        setTitleDraft(event.target.value);
    }
    function handleNoSave() {
        setTitleDraft(value);
        setEditingTitle(false);
    }
    return editingTitle ? ((0, jsx_runtime_1.jsx)(text_field_1.TextField, { color: 'white', variant: 'minimal', defaultValue: titleDraft, onChange: handleChange, onKeyDown: handleSave, onBlur: handleNoSave, autoFocus: true, typography: typographyVariant, multiline: true })) : ((0, jsx_runtime_1.jsx)(StyledButton, { size: 'small', variant: 'text', disableRipple: true, onClick: () => setEditingTitle(true), children: (0, jsx_runtime_1.jsx)(material_1.Typography, { variant: typographyVariant, align: 'left', children: value }) }));
}
exports.EditableTitle = EditableTitle;
