"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DragButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_material_1 = require("@mui/icons-material");
const material_1 = require("@mui/material");
function DragButton(props) {
    return ((0, jsx_runtime_1.jsx)(material_1.IconButton, { ...props, sx: { cursor: 'grab' }, children: (0, jsx_runtime_1.jsx)(icons_material_1.DragHandleRounded, {}) }));
}
exports.DragButton = DragButton;
