"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MinimalTextField = exports.MinimalVariantName = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
exports.MinimalVariantName = 'minimal';
const MinimalStyledTextField = (0, material_1.styled)(material_1.TextField, {
    shouldForwardProp: (prop) => prop !== 'typography',
})(({ typography, color, theme }) => ({
    '.MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& > .MuiInputBase-root': {
        backgroundColor: theme.palette.action.selected,
        color: color === 'white' ? theme.palette.common.white : undefined,
    },
    '& .MuiInputBase-root, & .MuiInput-root': theme.typography[typography || 'body1'],
}));
function MinimalTextField(props) {
    const newProps = { ...props, variant: 'outlined' };
    return (0, jsx_runtime_1.jsx)(MinimalStyledTextField, { ...newProps });
}
exports.MinimalTextField = MinimalTextField;
