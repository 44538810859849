import { StatsigProvider, useClientAsyncInit } from '@statsig/react-bindings';
import { runStatsigAutoCapture } from '@statsig/web-analytics';
import type { ConfigData } from 'rachael-server';
import { type PropsWithChildren, useEffect } from 'react';

const StatsigEnviroments = {
  dev: 'development',
  stage: 'staging',
  prod: 'production',
} as const;

/**
 * Set up (or not) and return Statsig client
 */
export const StatsigWrapper = ({
  children,
  configData,
}: PropsWithChildren<{ configData: ConfigData }>): JSX.Element => {
  const statsigEnabled = process.env['PUBLIC_STATSIG_ENABLED'] === 'true';
  const statsigKey = process.env['PUBLIC_STATSIG_KEY'] || '';
  const env = process.env['PUBLIC_ENV'];

  const { client } = useClientAsyncInit(
    statsigKey,
    {
      userID: configData?.user?.email,
    },
    {
      disableLogging: !statsigEnabled,
      environment: {
        tier: StatsigEnviroments[(env || 'dev') as keyof typeof StatsigEnviroments],
      },
    }
  );

  useEffect(() => {
    if (client && statsigEnabled) {
      runStatsigAutoCapture(client);
    }
  }, [client, statsigEnabled]);

  if (!statsigEnabled) {
    console.log(
      `Statsig disabled (flag = ${statsigEnabled}, key = ${
        statsigKey ? 'provided' : 'not provided'
      })`
    );

    return <>{children}</>;
  }

  return <StatsigProvider client={client}>{children}</StatsigProvider>;
};
